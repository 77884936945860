@import url("./fonts/fonts.css");

html, body {
    height: 100%;
}

body {
    font-family: 'Overpass', sans-serif;
    font-weight: normal;
    font-style: normal;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
}

h1, h2, h3, h4, h5, h6,
button, input, select, textarea {
    font-family: 'Overpass', sans-serif;
}
::-webkit-input-placeholder {
    font-family: 'Overpass', sans-serif;
}
:-moz-placeholder {
    font-family: 'Overpass', sans-serif;
}
::-moz-placeholder {
    font-family: 'Overpass', sans-serif;
}
:-ms-input-placeholder {
    font-family: 'Overpass', sans-serif;
}
