.ct-series-p .ct-bar,.ct-series-p .ct-line,.ct-series-p .ct-point,.ct-series-p .ct-slice-donut{
    stroke:#d70206
}
.ct-series-p .ct-area,.ct-series-p .ct-slice-pie{
    fill:#d70206
}
.ct-series-q .ct-bar,.ct-series-q .ct-line,.ct-series-q .ct-point,.ct-series-q .ct-slice-donut{
    stroke:#f05b4f
}
.ct-series-q .ct-area,.ct-series-q .ct-slice-pie{
    fill:#f05b4f
}
.ct-series-r .ct-bar,.ct-series-r .ct-line,.ct-series-r .ct-point,.ct-series-r .ct-slice-donut{
    stroke:#f4c63d
}
.ct-series-r .ct-area,.ct-series-r .ct-slice-pie{
    fill:#f4c63d
}
.ct-series-s .ct-bar,.ct-series-s .ct-line,.ct-series-s .ct-point,.ct-series-s .ct-slice-donut{
    stroke:#d17905
}
.ct-series-s .ct-area,.ct-series-s .ct-slice-pie{
    fill:#d17905
}
.ct-series-t .ct-bar,.ct-series-t .ct-line,.ct-series-t .ct-point,.ct-series-t .ct-slice-donut{
    stroke:#453d3f
}
.ct-series-t .ct-area,.ct-series-t .ct-slice-pie{
    fill:#453d3f
}
.ct-series-u .ct-bar,.ct-series-u .ct-line,.ct-series-u .ct-point,.ct-series-u .ct-slice-donut{
    stroke:#59922b
}
.ct-series-u .ct-area,.ct-series-u .ct-slice-pie{
    fill:#59922b
}
.ct-series-v .ct-bar,.ct-series-v .ct-line,.ct-series-v .ct-point,.ct-series-v .ct-slice-donut{
    stroke:#0544d3
}
.ct-series-v .ct-area,.ct-series-v .ct-slice-pie{
    fill:#0544d3
}
.ct-series-w .ct-bar,.ct-series-w .ct-line,.ct-series-w .ct-point,.ct-series-w .ct-slice-donut{
    stroke:#6b0392
}
.ct-series-w .ct-area,.ct-series-w .ct-slice-pie{
    fill:#6b0392
}
.ct-series-x .ct-bar,.ct-series-x .ct-line,.ct-series-x .ct-point,.ct-series-x .ct-slice-donut{
    stroke:#e6805e
}
.ct-series-x .ct-area,.ct-series-x .ct-slice-pie{
    fill:#e6805e
}
.ct-series-y .ct-bar,.ct-series-y .ct-line,.ct-series-y .ct-point,.ct-series-y .ct-slice-donut{
    stroke:#dda458
}
.ct-series-y .ct-area,.ct-series-y .ct-slice-pie{
    fill:#dda458
}
.ct-series-z .ct-bar,.ct-series-z .ct-line,.ct-series-z .ct-point,.ct-series-z .ct-slice-donut{
    stroke:#eacf7d
}
.ct-series-z .ct-area,.ct-series-z .ct-slice-pie{
    fill:#eacf7d
}
