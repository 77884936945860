.timeline-quarter-axis .tick line {
    color: #DEDEDE;
}

.timeline-quarter-axis {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #141419;
    text-anchor: start;
}

.timeline-year-axis .tick line {
    color: #DEDEDE;
}

.timeline-year-axis {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #141419;
    text-anchor: start;
}

.timeline-month-axis .tick line {
    color: #DEDEDE;
}

.timeline-month-axis {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #77808E;
    text-anchor: start;
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.timeline-week-axis .tick line {
    color: #DEDEDE;
}

.timeline-week-axis {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #77808E;
    text-anchor: start;
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.timeline-day-axis .tick line {
    color: #DEDEDE;
}

.timeline-day-axis {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #77808E;
    text-anchor: start;
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.timeline-day-axis-hide {
    transition: opacity 0.2s ease;
    opacity: 0;
}

.timeline-week-axis-hide {
    transition: opacity 0.2s ease;
    opacity: 0;
}

.timeline-month-axis-hide {
    transition: opacity 0.2s ease;
    opacity: 0;
}

.timeline-quarter-axis-hide {
    transition: opacity 0.2s ease;
    opacity: 0;
}

.timeline-year-axis-hide {
    transition: opacity 0.2s ease;
    opacity: 0;
}

svg .overlay {
    pointer-events: none;
}

svg .selection {
    pointer-events: all
}
